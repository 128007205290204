import { Injectable } from '@angular/core'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { FileService } from './file.service'
import { PDFDocument, PDFImage, PDFPage, StandardFonts, rgb } from 'pdf-lib'
import { ExamRequestGroup } from '../models/exam-request-group'
import { TranslateService } from '@ngx-translate/core'
import { Person } from '../models/person'

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {
  constructor(
    private fileService: FileService,
    private translateService: TranslateService
  ) { }

  async generateDeclarationPresence(imagePath: string, title: string, text: string, scheduledDate?: any) {
    const pdfDoc = await PDFDocument.create()
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const titleTextSize = 18
    const bodyTextSize = 12

    const page = pdfDoc.addPage()

    const margin = 80

    const { width, height } = page.getSize()

    if (!imagePath) {
      return
    }

    const imageExtension = CommonUtils.fileExtensionFromFileName(CommonUtils.fileNameFromFilePath(imagePath))

    const imageBlob = await this.fileService.downloadFile(imagePath)

    let pdfImage: PDFImage | undefined = undefined

    if (imageExtension == 'png') {
      pdfImage = await pdfDoc.embedPng(
        (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
      )

    } else if (imageExtension == 'jpg' || imageExtension == 'jpeg') {
      pdfImage = await pdfDoc.embedJpg(
        (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
      )
    }

    if (!pdfImage) {
      return
    }

    const imageDim = pdfImage.scaleToFit(200, 100)

    page.drawImage(pdfImage, {
      x: margin,
      y: height - margin / 2 - imageDim.height,
      width: imageDim.width,
      height: imageDim.height
    })

    const titleWidth = helveticaFont.widthOfTextAtSize(title, titleTextSize)

    page.drawText(title, {
      x: width / 2 - titleWidth / 2,
      y: height - 250,
      size: titleTextSize,
      font: helveticaFont,
    })

    page.drawText(text, {
      x: margin,
      y: height - 350,
      size: bodyTextSize,
      font: helveticaFont,
      maxWidth: width - margin * 2
    })

    const friendlyDate = CommonUtils.extendedFriendlyDateFormat(new Date())

    const dateWidth = helveticaFont.widthOfTextAtSize(friendlyDate, bodyTextSize)

    page.drawText(friendlyDate, {
      x: width - margin - dateWidth,
      y: height - 550,
      size: 12,
      font: helveticaFont,
    })

    page.drawLine({
      start: { x: 300, y: height - 650 },
      end: { x: width - margin, y: height - 650 },
      thickness: 1
    })

    const blob = new Blob([await pdfDoc.save()], { type: 'application/pdf' })
    CommonUtils.openPdfInTab(blob)
  }

  async generateRequestedExamsPdf(reqExamsGroup: ExamRequestGroup, patientInfo?: Person) {
    const pdfDoc = await PDFDocument.create()
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const titleTextSize = 30
    const bodyTextSize = 16
    patientInfo = patientInfo || reqExamsGroup.patient

    const page = pdfDoc.addPage()

    const margin = 80

    const { width, height } = page.getSize()

    const imageBlob = await CommonUtils.getImageAsBlob('assets/custom-icons/ml_logo.jpg')

    let pdfImage: PDFImage | undefined = undefined

    pdfImage = await pdfDoc.embedJpg(
      (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
    )

    if (!pdfImage) {
      return
    }

    const imageDim = pdfImage.scaleToFit(300, 200)

    page.drawImage(pdfImage, {
      x: width / 2 - imageDim.width / 2,
      y: height - 150,
      width: imageDim.width,
      height: imageDim.height
    })

    const title = 'Pedido de Exames'
    const titleWidth = helveticaFont.widthOfTextAtSize(title, titleTextSize)
    page.drawText(title, {
      x: width / 2 - titleWidth / 2,
      y: height - 200,
      size: titleTextSize,
      font: helveticaFont,
      color: rgb(0, 0.56, 0.77)
    })

    const friendlyDate = CommonUtils.extendedFriendlyDateFormat(new Date())
    const dateWidth = helveticaFont.widthOfTextAtSize(friendlyDate, bodyTextSize)
    page.drawText(friendlyDate, {
      x: width / 2 - dateWidth / 2,
      y: height - 230,
      size: bodyTextSize,
      font: helveticaFont,
    })

    const patientName = `Paciente: ${patientInfo.name}${patientInfo.dateOfBirth ? `, ${CommonUtils.getAge(patientInfo.dateOfBirth)}` : ''}`
    const patientNameWidth = helveticaFont.widthOfTextAtSize(patientName, bodyTextSize)
    page.drawText(patientName, {
      x: width / 2 - patientNameWidth / 2,
      y: height - 260,
      size: bodyTextSize,
      font: helveticaFont,
      color: rgb(0, 0, 0)
    })

    if (patientInfo.conventionName && patientInfo.conventionNumber) {
      const conventionInfo = `Convenção: ${patientInfo.conventionName}, Número: ${patientInfo.conventionNumber}`
      const conventionInfoWidth = helveticaFont.widthOfTextAtSize(conventionInfo, bodyTextSize)
      page.drawText(conventionInfo, {
        x: width / 2 - conventionInfoWidth / 2,
        y: height - 290,
        size: bodyTextSize,
        font: helveticaFont,
        color: rgb(0, 0, 0)
      })
    }

    let currentY = height - 360
    const text = reqExamsGroup.exam_requests.map(req => req.exam_request_type.name)
    text.forEach((exam, index) => {
      page.drawCircle({
        x: margin,
        y: currentY + 6,
        size: 3,
        color: rgb(0, 0, 0)
      })

      page.drawText(exam, {
        x: margin + 15,
        y: currentY,
        size: bodyTextSize,
        font: helveticaFont,
        maxWidth: width - margin * 2
      })

      // check how many times 60 characters fit inside the exam string
      const examLength = exam.length
      const maxChars = 60
      const lines = Math.ceil(examLength / maxChars)
      currentY -= lines * 20 + 20
    })

    const docBarCodeBlob = await CommonUtils.getImageAsBlob('assets/custom-icons/dr_ml_barcode.jpg')

    let docBarCodeImage: PDFImage | undefined = undefined

    docBarCodeImage = await pdfDoc.embedJpg(
      (await CommonUtils.blobToArrayBuffer(docBarCodeBlob)) as ArrayBuffer
    )

    if (!docBarCodeImage) {
      return
    }

    const docBarCodeImageDim = docBarCodeImage.scaleToFit(100, 50)

    page.drawImage(docBarCodeImage, {
      x: width - margin - docBarCodeImageDim.width,
      y: margin,
      width: docBarCodeImageDim.width,
      height: docBarCodeImageDim.height
    })

    const blob = new Blob([await pdfDoc.save()], { type: 'application/pdf' })
    CommonUtils.openPdfInTab(blob)
  }

}
