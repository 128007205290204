import { Injectable } from '@angular/core'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import { io } from 'socket.io-client'
import authentication from '@feathersjs/authentication-client'
import { NotificationModel } from '../models/notification-model'
import { NotificationService } from './notifications.service'
import { AppToastService, ToastType } from './app-toast-service.service'
import { CommonUtils } from '../utils/commonUtils'
import { ExamsRealTimeService } from './exams-real-time.service'
import { Exam } from '../models/exam'
import { environment } from 'src/environments/environment'
import { GenericRealTimeService } from './generic-real-time.service'
import { TicketsRealTimeService } from './tickets-real-time.service'
import { IssueHasAccount } from '../models/issue-has-account'
import { AppointmentsRealTimeService } from './appointments-real-time.service'
import { Appointment } from '../models/appointment'
import { HeaderInfoWarning } from '../models/header-info-warning'
import { ExamTypes } from '../utils/examTypes'
import { AppointmentUtils } from '../utils/appointmentUtils'
import { ExamRequest } from '../models/exam-request'
import { ExamRequestGroup } from '../models/exam-request-group'

@Injectable({
  providedIn: 'root'
})
export class FeathersjsService {
  public client = feathers()
  public socket: any
  private loggedInUserId!: number
  warningSound = new Audio('../../assets/sounds/bell.mp3')

  constructor(
    private notificationService: NotificationService,
    private toastService: AppToastService,
    private realTimeService: ExamsRealTimeService,
    private ticketsRealTimeService: TicketsRealTimeService,
    private appointmentsRealTimeService: AppointmentsRealTimeService,
    private genericRealTimeService: GenericRealTimeService
  ) { }

  initFeathersSocketConnection() {
    this.socket = io(environment.url, {
      transports: ['websocket']
    })

    this.client.configure(socketio(this.socket))

    this.client.service('exams').on('newExamInfo', (created: Exam) => {
      console.log('new exam info: ', created)
      this.realTimeService.emitExamInfo(created)
    })

    this.client.service('exams').on('requestExamUpdatedInfo', (created: Exam) => {
      // console.log('new requestExamUpdatedInfo: ', created)
      this.realTimeService.possibleReqExamUpdated(created)
    })

    // we receive this event so we can remove an exam from the list if it
    // no longer makes sense to be there
    this.client.service('exams').on('newExamState', (currentState: any) => {
      console.log('new exam state: ', currentState)
      this.realTimeService.emitNewExamState(currentState)
    })

    this.client.service('exams').on('newExamRepetitionState', (data: any) => {
      console.log('new exam repetition state data: ', data)
      if (data.repeat >= 0) {
        this.genericRealTimeService.emitNewExamToRepeat(data.repeat == 0 ? -1 : 1)
      }
    })

    this.client.service('exams').on('examRemoved', (deletedExam: any) => {
      console.log('exam deleted: ', deletedExam)
      this.realTimeService.examWasRemoved(deletedExam.examId)
    })

    this.client
      .service('exams')
      .on(
        'examLockState',
        (updated: { examId: number; lockedBy?: { id: number; name: string } }) => {
          this.realTimeService.pushNewExamLockState(updated)
        }
      )

    this.client.service('issues').on('updated', (updated: any) => {
      console.log('issue updated from sockets: ', updated)
      this.ticketsRealTimeService.emitUpdatedTicketInfo(updated)
    })

    this.client.service('issues').on('created', (newIssue: any) => {
      console.log('issue created and received from sockets: ', newIssue)
      this.ticketsRealTimeService.emitNewTicketCreated(newIssue)
    })

    this.client
      .service('issue-comments')
      .on(
        'newComments',
        (unreadCommentUpdate: { id: number; name: string; IssueHasAccount: IssueHasAccount }[]) => {
          this.ticketsRealTimeService.emitNewTicketsUnreadCount(unreadCommentUpdate)
        }
      )

    this.client.service('issue-comments').on('created', (commentCreated: any) => {
      this.ticketsRealTimeService.emitNewComment(commentCreated)
    })

    this.client
      .service('issue-has-account')
      .on('accountWasRemovedFromIssue', (deletedAccountIssue: IssueHasAccount) => {
        this.ticketsRealTimeService.accountWasRemovedFromTicket.next(deletedAccountIssue)
      })

    this.client
      .service('issue-has-account')
      .on('created', (createdAccountIssue: IssueHasAccount) => {
        this.ticketsRealTimeService.emitNewTicketCreated(createdAccountIssue.issue)
        CommonUtils.showNotification(
          'Nova notificação',
          'A sua conta foi adicionada à situação: #' + createdAccountIssue.issue_id
        )
      })

    // received new notification from account organization
    this.client.service('notifications').on('created', (created: NotificationModel) => {
      // console.log('new notification: ', created)
      if (!this.loggedInUserId) {
        this.loggedInUserId = CommonUtils.getLoggedInUserId()
      }

      if (this.loggedInUserId) {
        if (created.account_id == this.loggedInUserId) {
          created.createdAt = new Date()

          this.notificationService.newNotificationAdded(created)
          this.toastService.show(ToastType.SUCCESS, 'Notificação', 'Tem uma nova notificação')
        }
      }
    })

    this.client.service('appointments').on('appointmentCreated', (created: any) => {
      console.log('new appointment created: ', created)
      this.appointmentsRealTimeService.emitNewAppointmentCreated(created)
    })

    this.client.service('appointments').on('patientCheckedIn', (appointment: Appointment) => {
      console.log('new patient check in: ', appointment)
      const warningInfo = AppointmentUtils.infoMsgFromAppt(appointment)

      this.warningSound.play()

      const currentWarningInfo = this.genericRealTimeService.warningInfo.getValue()
      this.genericRealTimeService.warningInfo.next([...currentWarningInfo, warningInfo])
      // this.appointmentsRealTimeService.emitNewAppointmentCreated(created)
    })

    this.client.service('exam-request').on('newExamsRequestedInfo', (examsRequestedInfo: { storeId: number, newGroupWithReqs: ExamRequestGroup }) => {
      console.log('examsRequestedInfo: ', examsRequestedInfo)
      const warningInfo = AppointmentUtils.newExamReqInfo(examsRequestedInfo.newGroupWithReqs)

      this.warningSound.play()

      const currentWarningInfo = this.genericRealTimeService.warningInfo.getValue()
      this.genericRealTimeService.warningInfo.next([...currentWarningInfo, warningInfo])
      // this.appointmentsRealTimeService.emitNewAppointmentCreated(created)
    })

    this.client.service('exam-request').on('updated', (updated: ExamRequest) => {
      this.genericRealTimeService.examRequestInfoUpdated(updated)
    })

    this.client.service('exam-request-group').on('updated', (updated: ExamRequestGroup) => {
      this.genericRealTimeService.examRequestGroupUpdated(updated)
    })

    this.client.service('appointments').on('updated', (updated: Appointment) => {
      console.log('appointment updated: ', updated)
      if (updated.appointment_state_id != 3) {
        const currentWarningInfos = this.genericRealTimeService.warningInfo.getValue()
        const newWarningInfos = currentWarningInfos.filter(w => w.appointmentId != updated.id)
        this.genericRealTimeService.warningInfo.next(newWarningInfos)
      }

      this.appointmentsRealTimeService.emitUpdatedAppointmentInfo(updated)
    })

    this.client.service('appointments').on('removed', (removed: any) => {
      console.log('appointment removed: ', removed)
      this.appointmentsRealTimeService.emitDeletedAppointment(removed)
    })
  }

  exit() {
    this.client.teardown()
  }

  configureClientForAccountLogin() {
    this.client.configure(
      authentication({
        path: 'authentication'
      })
    )
  }

  reauthenticate(): Promise<any> {
    return this.client.reAuthenticate()
  }

  async authenticate(email: string, password: string) {
    try {
      // Authenticate with the local email/password strategy
      const authResult = await this.client.authenticate({
        strategy: 'local',
        email: email,
        password: password
      })

      // Show e.g. logged in dashboard page
    } catch (error: any) {
      // Show login page (potentially with `e.message`)
      console.error('Authentication error', error)
    }
  }
}
