<div class="navbar" *ngIf="isAuthenticated" (mouseleave)="onMouseLeaveNavbar()">
  <div class="left-side">
    <img src="../../../assets/custom-icons/hamburguer_icon.svg" style="cursor: pointer" (click)="toggleClass()" />
    <div class="searchContainer" [class.active]="searchIsActive">
      <img class="searchIcon" src="../../../assets/custom-icons/search_icon.svg" alt="" />
      <input type="text" [placeholder]="'search_placeholder' | translate" (focus)="searchFocused()"
        (focusout)="searchUnfocused()" tabindex="-1" (input)="onSearchChange($event)" autocomplete="new-search"
        [(ngModel)]="searchingValue" required />
    </div>
  </div>

  <div class="right-side">
    <div *ngIf="
        ([userRoles.TECHLEVEL1, userRoles.TECHLEVEL2, userRoles.EXTERNALTECH] | userRole) &&
        canRequestExams
      " style="margin-right: 15px; position: relative">
      <div></div>
      <button class="primary-button" [class.request-exams-btn]="examsAvailableForReq" style="padding: 0 10px"
        (mouseenter)="showRequestExamTypePopOver()" (click)="showRequestExamTypePopOver()">
        Obter Exame
      </button>
      <div class="request-exam-popover" *ngIf="requestExamTypePopoverVisible">
        <div class="exam-settings">
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam()">{{
            'any' | translate
            }}</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.ECG)">{{ 'ecg' | translate
            }} ({{ availableExamsCountByType[examTypes.ECG]?.count || '0' }})
          </span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.MAPA24)">{{ 'mapa24' |
            translate }} ({{
            availableExamsCountByType[examTypes.MAPA24]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.MAPA48)">{{ 'mapa48' |
            translate }} ({{
            availableExamsCountByType[examTypes.MAPA48]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.HOLTER24)">{{ 'holter24' |
            translate }} ({{
            availableExamsCountByType[examTypes.HOLTER24]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.HOLTER48)">{{ 'holter48' |
            translate }} ({{
            availableExamsCountByType[examTypes.HOLTER48]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.ECO)">{{ 'eco' | translate
            }} ({{
            availableExamsCountByType[examTypes.ECO]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.ECODOPPLER)">{{
            'eco-doppler' | translate }} ({{
            availableExamsCountByType[examTypes.ECODOPPLER]?.count || '0'
            }})</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="requestExam(examTypes.PROVA)">{{ 'prova' |
            translate }} ({{
            availableExamsCountByType[examTypes.PROVA]?.count || '0'
            }})</span>
          <hr />
        </div>
      </div>
    </div>

    <div style="margin-right: 15px; position: relative">
      <div class="flexRow5 pointer" (click)="toggleHelpPopover()" id="help-guide-button">
        <span class="fontRegular12 fontColorMediumDarkGray">{{ 'walkthrough' | translate }}</span>
        <img src="../../../assets/custom-icons/question-mark.svg" />
      </div>
      <div class="help-guide-popover" *ngIf="helpPopoverVisible">
        <div class="list-settings">
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="startInitialTour()">{{
            'view_initial_guide' | translate
            }}</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="startHowToSubmitExam()">{{
            'how_to_submit_exam' | translate
            }}</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="startHowToSubmitReceipt()">{{
            'how_to_submit_receipt' | translate
            }}</span>
          <hr />
          <span class="fontRegular12 fontColorMediumDarkGray" (click)="startHowToDownloadExam()">{{
            'how_to_download_exam' | translate
            }}</span>
          <hr />
        </div>
      </div>
    </div>

    <div style="margin-right: 15px; position: relative">
      <button class="create-ticket-button" routerLink="/dashboard/tickets/create-ticket">
        <span style="text-transform: uppercase;">{{ 'i_need_help' | translate }}</span>
      </button>
    </div>

    <div class="notifications" (click)="showNotificationsSidebar()">
      <img src="../../../assets/custom-icons/notification_bell_icon.svg" />
      <div class="notifications-counter">
        <span class="fontSemiBold10 fontColorWhite">{{ numNewNotifications }}</span>
      </div>
    </div>

    <div *ngIf="hasWarningInfos" class="pointer">
      <div class="warning-icon" (click)="showWarningInfosSidebar()"></div>
    </div>

    <div class="right-side" style="cursor: pointer; gap: 0.5em" (mouseenter)="showUserSettingsPopover()">
      <div class="user-info">
        <span class="fontSemiBold13 fontColorDefaultGray">{{ userName }}</span>
        <span class="fontRegular10 fontColorDarkGray" style="max-width: 200px; text-align: right">{{
          userRolesTranslated
          }}</span>
      </div>
      <img [src]="userProfilePic | safeHtml" style="width: 50px; height: 50px; border-radius: 50px" />
    </div>
  </div>
  <div class="user-settings-popover" *ngIf="userSettingsPopoverVisible">
    <div class="user-settings">
      <span class="fontRegular12 fontColorMediumDarkGray" (click)="showUserSettingsSidebar()">{{
        'settings' | translate
        }}</span>
      <hr />
      <span class="fontRegular12 fontColorMediumDarkGray" routerLink="dashboard/edit-profile">{{
        'user_profile' | translate
        }}</span>
      <hr />

      <div class="user-selected-language" (click)="toggleAllLanguages()">
        <div style="display: flex; gap: 5px">
          <img [src]="selectedLanguage.flag" alt="" />
          <span class="fontRegular12 fontColorMediumDarkGray">{{
            selectedLanguage.description
            }}</span>
        </div>
        <img [src]="
            languagesCollapsed
              ? '../../../assets/custom-icons/arrow_down_icon.svg'
              : '../../../assets/custom-icons/arrow_up_icon.svg'
          " />
      </div>
      <div class="all-languages" *ngIf="!languagesCollapsed">
        <div *ngFor="let language of languagesList">
          <div *ngIf="language.prefix != selectedLanguage.prefix" class="language"
            (click)="newLanguageSelected(language)">
            <img src="{{ language.flag }}" alt="" />
            <span class="fontRegular12 fontColorMediumDarkGray">{{ language.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>