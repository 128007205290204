import { EventEmitter, Component, OnInit, Output, OnDestroy } from '@angular/core'
import { SidebarLoaderService } from '../../services/sidebar-loader.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '../../services/auth.service'
import { CommonService } from '../../services/common.service'
import { Observable, Subscription } from 'rxjs'
import { RequestExamService } from 'src/app/services/request-exam.service'
import { AppToastService, ToastType } from 'src/app/services/app-toast-service.service'
import { UserRoles } from 'src/app/utils/userRoles'
import { ExamTypes } from 'src/app/utils/examTypes'
import { GenericRealTimeService } from 'src/app/services/generic-real-time.service'
import { ShepherdService } from 'angular-shepherd'
import { ShedpherdTutorialGuides } from '../../utils/shepherd-walkthrough-config'
import { AccountService } from 'src/app/services/account.service'
import { SearchService } from 'src/app/services/search.service'
import { ExamCount, ExamsRealTimeService } from 'src/app/services/exams-real-time.service'

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = true
  searchIsActive = false
  canRequestExams = false
  sidebarIsCollapsed = false
  userSettingsPopoverVisible = false
  helpPopoverVisible = false
  requestExamTypePopoverVisible = false
  urlBeforeSearching = ''
  languagesCollapsed = true
  selectedLanguage!: any
  userName = ''
  searchingValue = ''
  userRolesTranslated = ''
  userProfilePic = 'https://www.w3schools.com/howto/img_avatar.png'
  userPicSubs!: Subscription
  numNewNotifications = 0
  userRoles = UserRoles
  examTypes = ExamTypes
  tour!: ShepherdService
  showTutorialSubs!: Subscription
  searchValueSubs!: Subscription
  notificationsSubs!: Subscription
  availableExamsToRequestCountSubs!: Subscription
  warningInfoSubs!: Subscription
  shepherdTutorialGuides!: ShedpherdTutorialGuides
  hasWarningInfos = false
  availableExamsCountByType: ExamCount[] = []
  examsAvailableForReq = false


  languagesList = [
    {
      flag: '../../../assets/custom-icons/flag_pt_icon.svg',
      description: 'Português',
      prefix: 'pt'
    },
    {
      flag: '../../../assets/custom-icons/flag_en_icon.svg',
      description: 'English',
      prefix: 'en'
    },
    {
      flag: '../../../assets/custom-icons/flag_fr_icon.svg',
      description: 'Français',
      prefix: 'fr'
    },
    {
      flag: '../../../assets/custom-icons/flag_de_icon.svg',
      description: 'Deutsch',
      prefix: 'de'
    }
  ]

  @Output()
  sidebarCollapsedEvent = new EventEmitter<boolean>()

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private commonService: CommonService,
    private genericRealTimeService: GenericRealTimeService,
    private requestExamService: RequestExamService,
    private toastService: AppToastService,
    private shepherdService: ShepherdService,
    private accountService: AccountService,
    private searchService: SearchService,
    private examsRealTimeService: ExamsRealTimeService
  ) { }

  ngOnDestroy(): void {
    if (this.showTutorialSubs) {
      this.showTutorialSubs.unsubscribe()
    }

    if (this.userPicSubs) {
      this.userPicSubs.unsubscribe()
    }

    if (this.searchValueSubs) {
      this.searchValueSubs.unsubscribe()
    }

    if (this.notificationsSubs) {
      this.notificationsSubs.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.tour = this.shepherdService

    // initialize default language
    this.selectedLanguage = localStorage.getItem('defaultLang')
    const lang = localStorage.getItem('defaultLang')
    if (lang) {
      this.selectedLanguage = JSON.parse(lang)
    } else {
      this.selectedLanguage = {
        flag: '../../../assets/custom-icons/flag_pt_icon.svg',
        description: 'Português',
        prefix: 'pt'
      }
    }

    // get user profile pic
    const userPic = this.authService.getUserProfilePic()

    // get user name
    this.userName = this.authService.getUserInfo().name || ''

    // get user roles
    const userRoles = this.authService.getUserRoles()

    this.userRolesTranslated = userRoles
      .map(role => this.translateService.instant(UserRoles[role].toLowerCase()))
      .join(', ')

    if (userPic) {
      this.userProfilePic = userPic
    }

    this.userPicSubs = this.commonService.userProfilePic.subscribe(res => {
      if (res) {
        this.userProfilePic = res
      }
    })

    this.searchValueSubs = this.searchService.resetSearch.subscribe(res => {
      this.searchingValue = ''
      this.urlBeforeSearching = ''
    })

    this.canRequestExams = this.authService.getUserInfo().request_exams > 0 ? true : false

    this.notificationsSubs = this.genericRealTimeService.newNotificationsCount
      .asObservable()
      .subscribe({
        next: res => {
          this.numNewNotifications = res
        }
      })

    this.availableExamsToRequestCountSubs = this.examsRealTimeService.availableExamsToRequestCount
      .asObservable()
      .subscribe({
        next: res => {
          // create map of available exams count by type
          this.availableExamsCountByType = res

          this.examsAvailableForReq = this.availableExamsCountByType.length > 0

        }
      })

    this.warningInfoSubs = this.genericRealTimeService.warningInfo.asObservable().subscribe({
      next: res => {
        this.hasWarningInfos = res.length > 0
      }
    })
  }

  ngAfterViewInit() {
    this.shepherdTutorialGuides = new ShedpherdTutorialGuides(
      this.router,
      this.tour,
      this.accountService,
      this.authService
    )

    this.showTutorialSubs = this.commonService.showTutorial.subscribe(res => {
      if (res) {
        this.startInitialTour(false)
      }
    })
  }

  startInitialTour(cancelIcon = true) {
    this.onMouseLeaveNavbar()

    this.tour.defaultStepOptions = {
      cancelIcon: {
        enabled: cancelIcon
      },
      scrollTo: { behavior: 'smooth', block: 'center' }
    }
    this.tour.modal = true
    this.tour.confirmCancel = true
    this.tour.confirmCancelMessage = 'Tem a certeza que pretende sair da introdução?'

    if (this.authService.getUserInfo().account_has_stores.length > 0) {
      this.tour.addSteps(this.shepherdTutorialGuides.INITIALGUIDEWITHEXAMSUBMITTION as any)
    } else {
      this.tour.addSteps(this.shepherdTutorialGuides.INITIALGUIDEWITHOUTEXAMSUBMITTION as any)
    }

    if (!this.tour.isActive) {
      this.tour.start()
    }
  }

  startHowToSubmitExam() {
    this.onMouseLeaveNavbar()

    const numOfStores = this.authService.getUserInfo().account_has_stores?.length
    if (numOfStores <= 0) {
      this.toastService.show(
        ToastType.ERROR,
        'Erro',
        'Não tem nenhuma loja associada à sua conta, portanto não pode submeter exames.'
      )
      return
    }

    this.tour.defaultStepOptions = {
      cancelIcon: {
        enabled: true
      },
      scrollTo: { behavior: 'smooth', block: 'center' }
    }
    this.tour.modal = true
    this.tour.confirmCancel = true
    this.tour.confirmCancelMessage = 'Tem a certeza que pretende sair do guia?'

    this.tour.addSteps(this.shepherdTutorialGuides.HOW_TO_SUBMIT_EXAM as any)

    if (!this.tour.isActive) {
      this.tour.start()
    }
  }

  startHowToSubmitReceipt() {
    this.onMouseLeaveNavbar()

    this.tour.defaultStepOptions = {
      cancelIcon: {
        enabled: true
      },
      scrollTo: { behavior: 'smooth', block: 'center' }
    }
    this.tour.modal = true
    this.tour.confirmCancel = true
    this.tour.confirmCancelMessage = 'Tem a certeza que pretende sair do guia?'

    this.tour.addSteps(this.shepherdTutorialGuides.HOW_TO_SUBMIT_RECEIPT as any)

    if (!this.tour.isActive) {
      this.tour.start()
    }
  }

  startHowToDownloadExam() {
    this.onMouseLeaveNavbar()

    this.tour.defaultStepOptions = {
      cancelIcon: {
        enabled: true
      },
      scrollTo: { behavior: 'smooth', block: 'center' }
    }
    this.tour.modal = true
    this.tour.confirmCancel = true
    this.tour.confirmCancelMessage = 'Tem a certeza que pretende sair do guia?'

    this.tour.addSteps(this.shepherdTutorialGuides.HOW_TO_DOWNLOAD_EXAM as any)

    if (!this.tour.isActive) {
      this.tour.start()
    }
  }

  toggleAllLanguages() {
    this.languagesCollapsed = !this.languagesCollapsed
  }

  async requestExam(examTypeId?: number) {
    await this.requestExamService.requestExam(examTypeId)
      .then(res => {
        if (res.length > 0) {
          this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Novo exame solicitado com sucesso.')
          this.reloadRoute()
        } else {
          this.toastService.show(ToastType.ERROR, 'Erro', 'Não existem exames disponíveis.')
        }
      })
      .catch(err => {
        if (err.error.name === 'MAX_EXAMS') {
          this.toastService.show(ToastType.ERROR, 'Erro', 'Só pode no máximo 3 exames por relatar.')
        }
      })


  }

  reloadRoute() {
    const currentUrl = this.router.url
    const routeUrl = '/dashboard/exams/reception-exams'

    // Check if already on the same route
    if (currentUrl === routeUrl) {
      // Reload the component
      this.reloadCurrentRoute()
    } else {
      // Navigate to the route
      this.router.navigateByUrl(routeUrl)
    }
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl)
    })
  }

  newLanguageSelected(language: any) {
    this.selectedLanguage = language
    localStorage.setItem('defaultLang', JSON.stringify(this.selectedLanguage))
    this.translateService.use(language.prefix)
  }

  searchFocused() {
    this.searchIsActive = true
  }

  searchUnfocused() {
    this.searchIsActive = false
  }

  onSearchChange(inputEvent: Event) {
    if (!this.urlBeforeSearching) {
      this.urlBeforeSearching = this.router.url
      this.searchService.urlBeforeSearching = this.router.url
    }

    const searchValue = (inputEvent.target as HTMLInputElement).value
    if (searchValue.length > 0) {
      this.router.navigateByUrl('dashboard/search-results')
      this.router.navigate(['dashboard/search-results'], {
        queryParams: { searchValue: searchValue }
      })
      console.log('not empty: ', searchValue)
    } else {
      this.router.navigateByUrl(this.urlBeforeSearching)
      this.urlBeforeSearching = ''
    }
  }

  toggleClass() {
    this.sidebarIsCollapsed = !this.sidebarIsCollapsed
    this.sidebarCollapsedEvent.emit(this.sidebarIsCollapsed)
  }

  showNotificationsSidebar() {
    this.userSettingsPopoverVisible = false
    this.sidebarLoaderService.toggleNotificationsSidebar()
  }

  showWarningInfosSidebar() {
    this.userSettingsPopoverVisible = false
    this.sidebarLoaderService.toggleWarningsSidebar()
  }

  showUserSettingsSidebar() {
    this.userSettingsPopoverVisible = false
    this.sidebarLoaderService.toggleUserSettingsSidebar()
  }

  toggleHelpPopover() {
    this.helpPopoverVisible = !this.helpPopoverVisible
  }

  showUserSettingsPopover() {
    this.userSettingsPopoverVisible = true
  }

  showRequestExamTypePopOver() {
    this.requestExamTypePopoverVisible = true
  }

  onMouseLeaveNavbar() {
    this.userSettingsPopoverVisible = false
    this.requestExamTypePopoverVisible = false
    this.helpPopoverVisible = false
    this.languagesCollapsed = true
  }
}
