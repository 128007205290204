import { Exam } from '../models/exam'
import { Country } from '../models/country'
import { Document } from '../models/document'
import { ExamReportUtils } from './examReportUtils'
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { ExamTypes } from './examTypes'
import { DocumentFileTypes } from './documentFileTypes'
import { PDFDocument } from 'pdf-lib'
import { ExamTypesEditUrl } from './examTypesEditUrl'
import { Mod11_2 } from '@konfirm/iso7064'
import moment from 'moment-timezone'
import 'moment-timezone/data/packed/latest.json'
import { UserRoles } from './userRoles'

export class CommonUtils {
  // months in portuguese
  static months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  static async getPdfArrayBuffer(filePath: string): Promise<ArrayBuffer> {
    const response = await fetch(filePath); // Fetch the PDF

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const arrayBuffer = await response.arrayBuffer(); // Convert to ArrayBuffer
    return arrayBuffer;
  }

  static async getImageAsBlob(imagePath: string): Promise<Blob> {
    const response = await fetch(imagePath)
    const blob = await response.blob()
    return blob
  }

  static getRegion(firstDigit: string | undefined): string {
    if (!firstDigit) {
      return 'unknown'
    }
    switch (firstDigit) {
      case '1':
        return 'north'
      case '2':
        return 'central'
      case '3':
        return 'lisbon'
      case '4':
        return 'alentejo'
      case '5':
        return 'algarve'
      case '6':
        return 'azores'
      case '7':
        return 'madeira'
      default:
        return 'unknown'
    }
  }
  static async stringToSha256(string: string): Promise<string> {
    const textAsBuffer = new TextEncoder().encode(string)
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const digest = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

    return digest
  }

  static async getAsByteArray(file: File) {
    return new Uint8Array((await this.readFile(file)) as ArrayBufferLike)
  }

  static enumToArray(enumObject: any) {
    return Object.keys(enumObject)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ id: enumObject[key], name: key }))
  }

  static getScaledImageSize(
    originalWidth: number,
    originalHeight: number,
    targetHeight: number,
    maxWidth: number
  ) {
    const aspectRatio = originalWidth / originalHeight
    let scaledWidth = Math.round(targetHeight * aspectRatio)
    let scaledHeight = targetHeight

    if (scaledWidth > maxWidth) {
      scaledWidth = maxWidth
      scaledHeight = Math.round(maxWidth / aspectRatio)
    }

    return { width: scaledWidth, height: scaledHeight }
  }

  static openUrlInNewTab(url: string) {
    window.open(url, '_blank')
  }

  static getLoggedInUserId(): number {
    const userToken = localStorage.getItem('feathers-jwt')
    let payload
    if (userToken) {
      try {
        payload = JSON.parse(Buffer.from(userToken.split('.')[1], 'base64').toString('binary'))
      } catch (error) {
        console.log('invalid token')
      }
    } else {
      return 0
    }

    return payload.id
  }

  static customRegexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }
      const valid = regex.test(control.value)
      return valid ? null : error
    }
  }

  static requisitionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }
      const valid = Mod11_2.validate(control.value)
      return valid ? null : { requisitionInvalid: true }
    }
  }

  static hasNullParameter<T extends object>(obj: T): boolean {
    return Object.values(obj).some(value => value === null)
  }

  static readFile(file: File) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader()

      // Register event listeners
      reader.addEventListener('loadend', e => resolve(e.target!.result))
      reader.addEventListener('error', reject)

      // Read file
      reader.readAsArrayBuffer(file)
    })
  }

  static openPdfInTab(pdfBlob: Blob) {
    console.log('open pdf in tab')

    if (pdfBlob) {
      const newTabFile = new File([pdfBlob], 'exam.pdf', { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(newTabFile)
      window.open(fileURL, '_blank')
    }
  }

  static openFileInTab(pdfFile: File) {
    console.log('open pdf in tab')

    if (pdfFile) {
      const fileURL = URL.createObjectURL(pdfFile)
      window.open(fileURL, '_blank')
    }
  }

  static openPdfInNewTabFromDocumentPath(documentPath: string) {
    const fileURL = documentPath
    window.open(fileURL, '_blank')
  }

  static getFileTypeFromTypeId(typeId: number): string {
    switch (typeId) {
      case DocumentFileTypes.pdf:
        return 'application/pdf'
      case DocumentFileTypes.jpeg:
        return 'image/jpeg'
      case DocumentFileTypes.jpg:
        return 'image/jpeg'
      case DocumentFileTypes.png:
        return 'image/png'
      default:
        return ''
    }
  }

  // open image in new tab no matter the type
  static openImageInNewTab(imageBlob: Blob, filename: string, type: string) {
    const newTabFile = new File([imageBlob], filename, { type: type })
    const fileURL = URL.createObjectURL(newTabFile)
    window.open(fileURL, '_blank')
  }

  static async checkNumOfPagesInPdf(pdfFile: ArrayBuffer): Promise<number> {
    const pdfDoc = await PDFDocument.load(pdfFile)

    //get number of pages
    const numberOfPages = pdfDoc.getPageCount()

    return numberOfPages
  }

  // static convertUtcToUserTimeZone(utcDate: string): string {
  //   const date = new Date(utcDate)

  //   // Get the user's current time zone
  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  //   // Convert the date to the user's current time zone
  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     timeZone: userTimeZone,
  //     hour12: false, // Ensure 24-hour format
  //   }

  //   const formattedDate = date.toLocaleString("en-US", options)

  //   // Extract the date and time components
  //   const [datePart, timePart] = formattedDate.split(", ")

  //   // Reformat to the desired format
  //   const [month, day, year] = datePart.split("/")
  //   const [time, timezone] = timePart.split(" ")
  //   const [hour, minute, second] = time.split(":")
  //   const reformattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`
  //   return reformattedDate
  // }

  static convertUtcToUserTimeZone(utcDate: string): string {
    const reformattedDate = moment.utc(utcDate).local().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    return reformattedDate
  }

  static getEditReportUrl(examTypeId: number): string {
    return ExamTypesEditUrl[examTypeId as keyof typeof ExamTypesEditUrl] || '';
  }

  static testForEmailValidity(emailString: string): boolean {
    let textValidity = emailString
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

    if (textValidity) {
      return true
    } else {
      return false
    }
  }

  static filterDocumentsByFileName(
    filename: string,
    examDocuments: Document[],
    latest: boolean = false
  ): Document | null {
    const docs = examDocuments.filter(exDoc => {
      return CommonUtils.fileNameFromFilePath(exDoc.path) == filename
    })
    return docs.at(latest ? -1 : 0) ?? null
  }

  static getConvertedFiles(examDocuments: Document[]): Document[] {
    let convertedDocuments: Document[] = []

    for (const doc of examDocuments) {
      const documentName = CommonUtils.fileNameFromFilePath(doc.path)
      if (documentName.includes('converted')) {
        convertedDocuments.push(doc)
      }
    }
    return convertedDocuments
  }

  static getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000)
  }

  static blobToArrayBuffer(file: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer)
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    })
  }

  static calculateScaledWidth(
    originalWidth: number,
    originalHeight: number,
    desiredWidth: number
  ): number {
    const scaleFactor: number = desiredWidth / originalWidth
    const scaledHeight: number = originalHeight * scaleFactor
    return scaledHeight
  }

  static calculateScaledHeight(
    originalWidth: number,
    originalHeight: number,
    desiredHeight: number
  ): number {
    const scaleFactor: number = desiredHeight / originalHeight
    const scaledWidth: number = originalWidth * scaleFactor
    return scaledWidth
  }

  static blobToBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = err => reject(err)
      reader.readAsDataURL(blob)
    })
  }

  static validateSignExamData(signExamData: any) {
    console.log('signExamDate', signExamData)
    for (const key in signExamData) {
      if (!signExamData[key]) {
        throw new Error(`Missing or empty value for key: ${key}`)
      }
    }
  }

  static getDocumentTypes() {
    return Object.keys(DocumentFileTypes)
      .filter(k => typeof DocumentFileTypes[k as keyof typeof DocumentFileTypes] === 'number')
      .map(k => ({ id: DocumentFileTypes[k as keyof typeof DocumentFileTypes], extension: k }))
  }

  static getExamTypes() {
    return Object.keys(ExamTypes)
      .filter(k => typeof ExamTypes[k as keyof typeof ExamTypes] === 'number')
      .map(k => ({ id: ExamTypes[k as keyof typeof ExamTypes], name: k }))
  }

  static getExamTypeName(examTypeId: number): string {
    const examTypes = this.getExamTypes()
    const examType = examTypes.find(e => e.id === examTypeId)
    return examType?.name || ''
  }

  static showNotification(title: string, body: string): void {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, { body })
      notification.onclick = () => {
        // Handle notification click event if needed
        console.log('Notification clicked.')
      }
    } else {
      console.log('Notifications are not permitted.')
    }
  }

  static fileNameFromFilePath(filePath: string): string {
    let index = filePath.indexOf('_')
    return filePath.substring(index + 1)
  }

  static fileExtensionFromFileName(filePath: string): string {
    let fileExtension = (filePath.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1]
    return fileExtension
  }

  static addOrRemoveItemFromList(item: any, list: any[]): any[] {
    let index = list.findIndex(o => o === item)

    if (index === -1) list.push(item)
    else list.splice(index, 1)
    return list
  }

  static getCountries(): Country[] {
    const countries = localStorage.getItem('countries')

    if (countries) {
      return JSON.parse(countries)
    } else {
      return []
    }
  }

  static sortExamsDesc(examList: Exam[]): Exam[] {
    return examList.sort((a: Exam, b: Exam) => {
      return <any>new Date(b.createdAt) - <any>new Date(a.createdAt)
    })
  }

  static getDateSubstring(date: Date): string {
    return new Date(date).toISOString().substring(0, 16)
  }

  static sortExamsByDateAsc(examList: Exam[]): Exam[] {
    return examList.sort((a: Exam, b: Exam) => {
      return <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
    })
  }

  static getDaysInMonth(date: Date): number {
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Months are zero-based, so add 1

    // Create a new date for the next month's first day
    const nextMonthDate = new Date(year, month, 1)

    // Subtract 1 from the next month's first day to get the last day of the current month
    const lastDayOfMonth = new Date(nextMonthDate.getTime() - 1)

    return lastDayOfMonth.getDate()
  }

  static getMonthName(monthNumber: number): string {
    switch (monthNumber) {
      case 1:
        return 'january'
      case 2:
        return 'february'
      case 3:
        return 'march'
      case 4:
        return 'april'
      case 5:
        return 'may'
      case 6:
        return 'june'
      case 7:
        return 'july'
      case 8:
        return 'august'
      case 9:
        return 'september'
      case 10:
        return 'october'
      case 11:
        return 'november'
      case 12:
        return 'december'
      default:
        return 'month'
    }
  }

  static moveUrgentExamsToTopOfList(examList: Exam[]): Exam[] {
    let urgentExams: Exam[] = []

    for (const examIndex in examList) {
      let urgentProperty = ExamReportUtils.getExamPropertyByName(
        'urgent',
        examList[examIndex].exam_proprieties
      )?.value

      if (urgentProperty == 'true') {
        const items = examList.splice(parseInt(examIndex), 1)
        examList.unshift(...items)
      }
    }

    return examList
  }

  static generateRandomString(n: number) {
    let randomString = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < n; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return randomString
  }

  static downloadFileFromArrayBuffer(filename: string, binaryFile: ArrayBuffer) {
    var blob = new Blob([binaryFile], { type: 'application/pdf' })
    const file = new File([blob], filename, {
      type: blob.type
    })

    const link = document.createElement('a')

    // Add file content in the object URL
    link.href = URL.createObjectURL(file)

    // Add file name
    link.download = filename

    // Add click event to <a> tag to save file.
    link.click()
  }

  static generateReportNumber(examId: string): string {
    let randomNumber = Math.random() * 100000

    return randomNumber + new Array(6 - examId.length).fill('0').join('')
  }

  static saveFileLocally(file: File) {
    const link = document.createElement('a')

    // Add file content in the object URL
    link.href = URL.createObjectURL(file)

    // Add file name
    link.download = file.name

    // Add click event to <a> tag to save file.
    link.click()
  }

  static booleanStringToBoolean(string: string): boolean {
    if (string == 'true') {
      return true
    } else {
      return false
    }
  }

  static convertBooleanArrayToNumber(booleanArray: boolean[]): number {
    let result = 0

    booleanArray.forEach((isActive, index) => {
      if (isActive) {
        const apptTypeValue = Math.pow(2, index)
        result |= apptTypeValue
      }
    })

    return result
  }

  static convertNumberToBooleanArray(number: number): boolean[] {
    let result: boolean[] = []

    while (number > 0) {
      result.push(number % 2 === 1)
      number = Math.floor(number / 2)
    }

    return result
  }

  static friendlyDateFormat(date?: Date, showTime = true): string {
    if (!date) {
      return ''
    }

    const dateFormat = showTime ? 'DD/MM/YYYY - HH[h]mm[m]' : 'DD/MM/YYYY'
    return moment(date).tz('Europe/Lisbon').format(dateFormat)
  }

  static holterReportDateFormat(date: Date) {
    return moment(date).format('HH:mm[m] [de] DD/MM/YYYY')
  }

  static simpleDateFormat(date?: Date): string {
    return moment(date).format('DD/MM/YYYY')
  }

  static extendedFriendlyDateFormat(dateToFormat: any): string {
    const date = new Date(dateToFormat)
    if (date.toString() != 'Invalid date') {
      return `${date.getDate()} de ${this.months[date.getMonth() || 0]} de ${date.getFullYear()}`
    } else {
      return ""
    }
  }

  static getAge(birth: Date): string {
    let birthdate = new Date(birth!)

    let d1 = birthdate!.getDate()
    let m1 = birthdate!.getMonth() + 1
    let y1 = birthdate!.getFullYear()

    let date = new Date()
    let d2 = date.getDate()
    let m2 = 1 + date.getMonth()
    let y2 = date.getFullYear()
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (d1 > d2) {
      d2 = d2 + month[m2 - 1]
      m2 = m2 - 1
    }
    if (m1 > m2) {
      m2 = m2 + 12
      y2 = y2 - 1
    }
    let y = y2 - y1

    return y + ' Anos'
  }

  static getUserToken(): string {
    const token = localStorage.getItem('feathers-jwt')
    return token ? token : ''
  }

  static getUserRoles(): number[] {
    const token = this.getUserToken()
    const userRoles = JSON.parse(
      Buffer.from(token.split('.')[1], 'base64').toString('binary')
    ).roleIds

    return userRoles
  }

  static isExternalTechOrTechLevel1(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.TECHLEVEL1 || UserRoles.EXTERNALTECH)
  }

  static isExternalTech(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.EXTERNALTECH)
  }

  static isTechLevel1(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.EXTERNALTECH)
  }

  static base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
}
