import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ApiResponse } from '../models/apiResponse'
import { ExamRequestType } from '../models/exam-request-type'

@Injectable({
  providedIn: 'root'
})
export class ExamRequestService {
  private url = `${environment.url}/exam-request/` //server
  private urlRequestTypes = `${environment.url}/exam-request-type/` //server
  private urlExamRequestGroup = `${environment.url}/exam-request-group/` //server

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) { }

  getExamRequestTypes(): Promise<ApiResponse<ExamRequestType>> {
    return firstValueFrom(this.http.get<ApiResponse<ExamRequestType>>(`${this.urlRequestTypes}`, this.httpOptions))
  }

  addExamRequestType(examRequestType: ExamRequestType): Promise<any> {
    return firstValueFrom(this.http.post<any>(`${this.urlRequestTypes}`, examRequestType, this.httpOptions))
  }

  requestExams(storeId: number, patientId: number, selectedReqTypes: ExamRequestType[]): Promise<any> {
    return firstValueFrom(this.http.post<any>(`${this.url}`, { storeId, patientId, selectedReqTypes }, this.httpOptions))
  }

  editRequestGroup(groupId: number, updatedReqTypes: ExamRequestType[]): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'X-Service-Method': 'editGroup',
      'Content-Type': 'application/json'
    })
    return firstValueFrom(this.http.post<any>(`${this.url}`, { groupId, updatedReqTypes }, { headers: httpHeaders }))
  }

  updateExamReqGroupState(groupId: number, state: number): Promise<any> {
    return firstValueFrom(this.http.put<any>(`${this.urlExamRequestGroup}${groupId}`, { state }))
  }

  updateExamReqState(examReqId: number, state: number): Promise<any> {
    return firstValueFrom(this.http.put<any>(`${this.url}${examReqId}`, { state }))
  }
}
