import { UserRoles } from '../../utils/userRoles'
interface RoleGroups {
  [key: string]: UserRoles[]
}

const RoleGroups: RoleGroups = {
  // Permission groups for exam tabs
  examsTab: [],
  signExams: [UserRoles.DOCTOR],
  arquivedExams: [
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR
  ],
  finalizedExams: [UserRoles.SUPERVISOR, UserRoles.DOCTOR, UserRoles.TECHLEVEL2],
  receptionExams: [
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR
  ],
  reportExams: [
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR
  ],
  seeReport: [UserRoles.SUPERVISOR, UserRoles.DOCTOR],
  // Store exam Submittion/submitted exams/exams that need attention
  submitExam: [
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR
  ],
  storeSubmittedExams: [
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.DOCTOR
  ],
  storeSubmittedExamsAttention: [
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR
  ],
  appointments: [],
  scheduleOnlineAppointment: [
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR
  ],
  scheduleAppointments: [
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR
  ],
  checkAppointments: [
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR,
    UserRoles.VIDEOCONSULTATION
  ],
  // Permission groups for tickets tab
  ticketsTab: [],
  createTicket: [
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.SALES,
    UserRoles.MANAGER
  ],
  ticketsOverview: [
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.SALES,
    UserRoles.MANAGER
  ],
  finalizedTickets: [
    UserRoles.DOCTOR,
    UserRoles.SUPERVISOR,
    UserRoles.TECHLEVEL1,
    UserRoles.TECHLEVEL2,
    UserRoles.STORELEVEL1,
    UserRoles.STORELEVEL2,
    UserRoles.EXTERNALTECH,
    UserRoles.SALES,
    UserRoles.MANAGER
  ],
  // Permissions groups for mcdt tab
  mcdtTab: [UserRoles.ESP],
  renders: [UserRoles.ADMIN, UserRoles.ESP],
  renderInvoices: [UserRoles.ADMIN, UserRoles.ESP],
  // Permission groups for management tab
  managementTab: [],
  companies: [UserRoles.MANAGER],
  invoices: [UserRoles.MANAGER],
  listings: [UserRoles.MANAGER],
  insurances: [UserRoles.MANAGER, UserRoles.STORELEVEL2],
  complaints: [UserRoles.MANAGER],
  stores: [UserRoles.MANAGER],
  material: [UserRoles.MANAGER],
  metrics: [UserRoles.MANAGER],
  patients: [
    UserRoles.MANAGER,
    UserRoles.TECHLEVEL2,
    UserRoles.SUPERVISOR,
    UserRoles.STORELEVEL2,
    UserRoles.VIDEOCONSULTATION
  ],
  people: [UserRoles.MANAGER],
  receipts: [UserRoles.MANAGER, UserRoles.STORELEVEL1, UserRoles.STORELEVEL2],
  announcements: [UserRoles.ADMIN],
  permissions: [UserRoles.ADMIN],
  storeAccessRequests: [UserRoles.ADMIN],
  appointmentsAvailability: [UserRoles.TECHLEVEL1, UserRoles.TECHLEVEL2, UserRoles.DOCTOR, UserRoles.VIDEOCONSULTATION],
  accountFunctions: [UserRoles.TECHLEVEL2, UserRoles.DOCTOR],
  // Permissiosn groups for
  toolsTab: [],
  codes: [UserRoles.MANAGER],
  documents: [],
  // Permission to edit exam assigned account
  assignExam: [UserRoles.SUPERVISOR]
}

// Add every unique user role to the exams tab, so the role can interact with the exams tab toggle button
RoleGroups['examsTab'] = [
  ...new Set([
    ...RoleGroups['signExams'],
    ...RoleGroups['arquivedExams'],
    ...RoleGroups['finalizedExams'],
    ...RoleGroups['receptionExams'],
    ...RoleGroups['reportExams']
  ])
]

// Add every unique user role to the appointments tab, so the role can interact with the appointments tab toggle button
RoleGroups['appointments'] = [
  ...new Set([...RoleGroups['scheduleAppointments'], ...RoleGroups['checkAppointments']])
]

// Add every unique user role to the tickets tab, so the role can interact with the tickets tab toggle button
RoleGroups['ticketsTab'] = [
  ...new Set([
    ...RoleGroups['createTicket'],
    ...RoleGroups['ticketsOverview'],
    ...RoleGroups['finalizedTickets']
  ])
]

RoleGroups['mcdtTab'] = [...new Set([...RoleGroups['renders'], ...RoleGroups['renderInvoices']])]

// Add every unique user role to the management tab, so the role can interact with the management tab toggle button
RoleGroups['managementTab'] = [
  ...new Set([
    ...RoleGroups['companies'],
    ...RoleGroups['invoices'],
    ...RoleGroups['listings'],
    ...RoleGroups['insurances'],
    ...RoleGroups['complaints'],
    ...RoleGroups['stores'],
    ...RoleGroups['material'],
    ...RoleGroups['metrics'],
    ...RoleGroups['patients'],
    ...RoleGroups['people'],
    ...RoleGroups['receipts'],
    ...RoleGroups['announcements'],
    ...RoleGroups['permissions'],
    ...RoleGroups['storeAccessRequests'],
    ...RoleGroups['appointmentsAvailability'],
    ...RoleGroups['accountFunctions']
  ])
]

// Add every unique user role to the tools tab, so the role can interact with the tools tab toggle button
RoleGroups['toolsTab'] = [...new Set([...RoleGroups['codes'], ...RoleGroups['documents']])]

// add admin to every RoleGroup
for (let key in RoleGroups) {
  RoleGroups[key].push(UserRoles.ADMIN)
}

export default RoleGroups
