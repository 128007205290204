<div class="sidebar" (mouseleave)="closeAllSubMenusOnMouseLeaveSidebar()">
  <div class="logo-container" style="cursor: pointer">
    <img class="logo" [src]="
        collapsed
          ? '../../../assets/custom-icons/logo_collapsed.svg'
          : '../../../assets/custom-icons/logo.svg'
      " routerLink="/dashboard" />
  </div>
  <ul class="sidebar-nav" [style.overflow-y]="collapsed ? '' : 'auto'">
    <!-- EXAMS TAB -->
    <div style="position: relative" *appShowForRoles="roleGroups['examsTab']">
      <li class="sidebar-nav-item" [class.collapsed]="collapsed" (click)="toggleExamsSubMenu()">
        <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/exams', false)"
          [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/sidebar_exams_icon.svg" alt="Exames"
              (mouseenter)="mouseOverExamsItem()" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'exams' | translate
              }}</span>
          </div>
          <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
        </div>
      </li>
      <ul class="sidebar-sub-menu" *ngIf="showExamsSubMenu" [class.collapsed]="collapsed">
        <li class="sidebar-sub-menu-item" routerLink="dashboard/exams/reception-exams"
          routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['receptionExams']">
          {{ 'reception_exams' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/exams/report-exams" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['reportExams']">
          {{ 'report_exams' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/exams/sign-exams" routerLinkActive="sub-item-active"
          *appShowForRoles="roleGroups['signExams']">
          {{ 'sign_exams' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/exams/finalized-exams"
          routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['finalizedExams']">
          {{ 'finalized_exams' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/exams/arquived-exams" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['arquivedExams']">
          {{ 'arquived_exams' | translate }}
        </li>
      </ul>
    </div>

    <!-- SUBMITTED EXAMS TAB FOR STORE WITHOUT ATTACHED FILE -->
    <div id="tabExamsWithoutFile">
      <li *appShowForRoles="roleGroups['storeSubmittedExams']" class="sidebar-nav-item"
        routerLink="dashboard/submissions/store-exams-nofile" (click)="closeAllSubMenus()"
        [class.collapsed]="collapsed">
        <div class="sidebar-nav-link" routerLinkActive="item-active" [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/exams_no_file.svg" alt="Submissões" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'submitted_exams_no_file' | translate
              }}</span>
          </div>
        </div>
      </li>
    </div>

    <!-- SUBMITTED EXAMS THAT NEED ATTENTION TAB FOR STORE -->
    <div id="tabExamsToRepeat">
      <li *appShowForRoles="roleGroups['storeSubmittedExamsAttention']" class="sidebar-nav-item"
        routerLink="dashboard/submissions/store-exams-attention" (click)="closeAllSubMenus()"
        [class.collapsed]="collapsed">
        <div class="sidebar-nav-link" routerLinkActive="item-active" [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/exams_to_repeat.svg" alt="Submissões" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'submitted_exams_attention' | translate
              }}</span>
            <div class="badge" *ngIf="examsToRepeatCount">
              {{ examsToRepeatCount <= 99 ? examsToRepeatCount : '99+' }} </div>
            </div>
          </div>
      </li>
    </div>

    <!-- SUBMITTED EXAMS TAB FOR STORE -->
    <div id="tabSubmittedExams">
      <li id="tabSubmittedExamsItem" *appShowForRoles="roleGroups['storeSubmittedExams']" class="sidebar-nav-item"
        routerLink="dashboard/submissions/store-exams" (click)="closeAllSubMenus()" [class.collapsed]="collapsed">
        <div class="sidebar-nav-link" routerLinkActive="item-active" [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/submitted_exams.svg" alt="Submissões" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'submitted_exams' | translate
              }}</span>
          </div>
        </div>
      </li>
    </div>

    <!-- SUBMISSIONS TAB -->
    <li id="tabSubmitExam" *appShowForRoles="roleGroups['submitExam']" class="sidebar-nav-item"
      routerLink="dashboard/submissions/submit-exam" (click)="closeAllSubMenus()" [class.collapsed]="collapsed">
      <div class="sidebar-nav-link" [class.collapsed]="collapsed">
        <div class="nav-link-text">
          <img src="../../../assets/custom-icons/sidebar_submission_icon.svg" alt="Submissões" />
          <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
            'submit_exam' | translate
            }}</span>
        </div>
      </div>
    </li>

    <!-- APPOINTMENTS TAB -->
    <ng-container *ngIf="hasAccessToAppointments">
      <div style="position: relative" *appShowForRoles="roleGroups['appointments']">
        <li class="sidebar-nav-item" [class.collapsed]="collapsed" (click)="toggleTeleheartSubMenu()">
          <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/appointments', false)"
            [class.collapsed]="collapsed">
            <div class="nav-link-text">
              <img src="../../../assets/custom-icons/sidebar_teleheart_icon.svg" alt="Consultas"
                (mouseenter)="mouseOverAppointmentsItem()" />
              <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
                'appointment.appointments' | translate
                }}</span>
            </div>
            <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
          </div>
        </li>
        <ul class="sidebar-sub-menu" *ngIf="showTeleheartSubMenu" [class.collapsed]="collapsed">
          <li class="sidebar-sub-menu-item" routerLink="dashboard/appointments/schedule-appointment"
            routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
            *appShowForRoles="roleGroups['scheduleAppointments']">
            {{ 'appointment.schedule_appointment' | translate }}
          </li>
          <li class="sidebar-sub-menu-item" routerLink="dashboard/appointments/check-appointments"
            routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
            *appShowForRoles="roleGroups['checkAppointments']">
            {{ 'appointment.check_appointments' | translate }}
          </li>
        </ul>
      </div>
    </ng-container>

    <!-- TICKETS TAB -->
    <div style="position: relative" *appShowForRoles="roleGroups['ticketsTab']" id="tabTickets">
      <li class="sidebar-nav-item" [class.collapsed]="collapsed" (click)="toggleTicketsSubMenu()">
        <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/tickets', false)"
          [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/sidebar_tickets_icon.svg" alt="Situações"
              (mouseenter)="mouseOverTicketsItem()" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'situations' | translate
              }}</span>
            <div class="badge" *ngIf="hasUnseenNotifications">{{ hasUnseenNotifications }}</div>
          </div>
          <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
        </div>
      </li>
      <ul class="sidebar-sub-menu" *ngIf="showTicketsSubMenu" [class.collapsed]="collapsed">
        <li *appShowForRoles="roleGroups['createTicket']" class="sidebar-sub-menu-item"
          routerLink="dashboard/tickets/create-ticket" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'create_situation' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['ticketsOverview']" class="sidebar-sub-menu-item"
          routerLink="dashboard/tickets/tickets-overview" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'tickets_overview' | translate }}
        </li>
        <!-- <li
          *appShowForRoles="roleGroups['finalizedTickets']"
          class="sidebar-sub-menu-item"
          routerLink="dashboard/tickets/finalized-tickets"
          routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'finalized_tickets' | translate }}
        </li> -->
      </ul>
    </div>

    <!-- MCDT TAB -->
    <div style="position: relative" *appShowForRoles="roleGroups['mcdtTab']" id="tabMCDT">
      <li class="sidebar-nav-item" [class.collapsed]="collapsed" (click)="toggleMCDTSubMenu()">
        <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/mcdt', false)"
          [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/sidebar_tools_icon.svg" alt="Ferramentas"
              (mouseenter)="mouseOverToolsItem()" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'MCDT' | translate
              }}</span>
          </div>
          <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
        </div>
      </li>
      <ul class="sidebar-sub-menu" *ngIf="showMCDTSubMenu" [class.collapsed]="collapsed">
        <li class="sidebar-sub-menu-item" routerLink="dashboard/mcdt/renders" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['renders']">
          {{ 'renders' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/mcdt/render-invoices" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['renderInvoices']">
          {{ 'invoices' | translate }}
        </li>
      </ul>
    </div>

    <!-- MANAGEMENT TAB -->
    <div style="position: relative" *appShowForRoles="roleGroups['managementTab']" id="tabManagement">
      <li class="sidebar-nav-item" [class.collapsed]="collapsed" id="tabManagementItem"
        (click)="toggleManagementSubMenu()">
        <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/management', false)"
          [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/sidebar_management_icon.svg" alt="Gestão"
              (mouseenter)="mouseOverManagementItem()" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'management' | translate
              }}</span>
          </div>
          <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
        </div>
      </li>
      <ul class="sidebar-sub-menu" *ngIf="showManagementSubMenu" [class.collapsed]="collapsed">
        <ng-container *ngIf="loggedInUserInfo.organization_id == 1">
          <li *appShowForRoles="roleGroups['insurances']" class="sidebar-sub-menu-item"
            routerLink="dashboard/management/insurances" routerLinkActive="sub-item-active"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'insurances' | translate }}
          </li>
        </ng-container>
        <li *appShowForRoles="roleGroups['accountFunctions']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/account-functions" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'account_functions' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['appointmentsAvailability']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/appointments-availability" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'set_appointments_availability' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['companies']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/companies" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'companies' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['invoices']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/invoices" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'invoices' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['listings']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/listings" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'listings' | translate }} (DOING)
        </li>
        <li *appShowForRoles="roleGroups['complaints']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/complaints" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'complaints_book' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['stores']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/stores" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'stores' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['material']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/material" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'material' | translate }}
        </li>
        <li *appShowForRoles="roleGroups['metrics']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/metrics" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'metrics' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/management/patients" routerLinkActive="sub-item-active"
          *appShowForRoles="roleGroups['patients']">
          {{ 'patients' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/management/people" routerLinkActive="sub-item-active"
          *appShowForRoles="roleGroups['people']">
          {{ 'people' | translate }}
        </li>
        <li id="managementTabReceipts" *appShowForRoles="roleGroups['receipts']" class="sidebar-sub-menu-item"
          routerLink="dashboard/management/receipts-deposits" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }">
          {{ 'receipts_deposits' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/management/store-access-request"
          routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['storeAccessRequests']">
          {{ 'store_access_request' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/management/permissions"
          routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['permissions']">
          {{ 'permissions' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/management/announcements"
          routerLinkActive="sub-item-active" [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['announcements']">
          {{ 'announcements' | translate }}
        </li>
      </ul>
    </div>

    <!-- TOOLS TAB -->
    <div style="position: relative" *appShowForRoles="roleGroups['toolsTab']">
      <li class="sidebar-nav-item" [class.collapsed]="collapsed" (click)="toggleToolsSubMenu()">
        <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/tools', false)"
          [class.collapsed]="collapsed">
          <div class="nav-link-text">
            <img src="../../../assets/custom-icons/sidebar_tools_icon.svg" alt="Ferramentas"
              (mouseenter)="mouseOverToolsItem()" />
            <span *ngIf="!collapsed" class="fontSemiBold13 fontColorDefaultGray">{{
              'tools' | translate
              }}</span>
          </div>
          <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" *ngIf="!collapsed" />
        </div>
      </li>
      <ul class="sidebar-sub-menu" *ngIf="showToolsSubMenu" [class.collapsed]="collapsed">
        <li class="sidebar-sub-menu-item" routerLink="dashboard/tools/codes" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['codes']">
          {{ 'codes' | translate }}
        </li>
        <li class="sidebar-sub-menu-item" routerLink="dashboard/tools/documents" routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }" *appShowForRoles="roleGroups['documents']">
          {{ 'documents' | translate }}
        </li>
      </ul>
    </div>
  </ul>

  <div class="logout-container" [class.collapsed]="collapsed" (click)="userLogout()">
    <img src="../../../assets/custom-icons/logout_icon.svg" />
    <span class="fontRegular13 fontColorMediumDarkGray" *ngIf="!collapsed">{{
      'logout' | translate
      }}</span>
  </div>
</div>