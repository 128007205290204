import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Observable, firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { Appointment } from '../models/appointment'
import { ApiResponse } from '../models/apiResponse'
import { createHttpParams } from '../utils/http-params'
import { ApiOperationTypes } from '../utils/api-operation-types'
import { StepOneData } from '../interfaces/step-one-create-appointment'
import { StepTwoData } from '../interfaces/step-two-create-appointment'
import { createHttpBody } from '../utils/http-body'
import { SocketInterceptorService } from './socket-interceptor.service'
import { AppointmentProperty } from '../models/appointment-property'
import { AppointmentHasDocument } from '../models/appointment-has-document'
import { ExamRequestGroup } from '../models/exam-request-group'
import { Store } from '../models/store'

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  private url = `${environment.url}/appointments` //server
  private appointmentHasDocumentUrl = `${environment.url}/appointment-has-document` //server
  private appointmentPropertyUrl = `${environment.url}/appointment-property` //server

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  stepOneData?: StepOneData
  stepTwoData?: StepTwoData
  prefilledExamTypeIds?: number[]
  schedulingForExamGroup?: ExamRequestGroup

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private socketInterceptor: SocketInterceptorService
  ) { }

  userId = this.authService.getLoggedInUserId()

  prefillStepOneDateFromExamGroup(examGroup: ExamRequestGroup, prefilledExamTypeIds: number[]): boolean {
    if (!examGroup.patient || !examGroup.store || !examGroup.doctor) {
      return false
    }

    this.stepOneData = {
      formValues: {
        patientName: examGroup.patient.name || "",
        patientBirthDate: examGroup.patient.dateOfBirth ? new Date(examGroup.patient.dateOfBirth).toISOString().split('T')[0] : "",
        patientGender: examGroup.patient.gender || 0,
        patientWeight: examGroup.patient.weight || 0,
        patientHeight: examGroup.patient.height || 0,
        patientIdNumber: examGroup.patient.identNumber || "",
        patientTaxNumber: examGroup.patient.taxNumber || "",
        patientContact: examGroup.patient.contact?.phone || "",
        patientAddress: examGroup.patient.address?.street || "",
        patientPostCode: examGroup.patient.address?.zip || "",
        patientLocality: examGroup.patient.address?.city || "",
        patientEmail: examGroup.patient.email || "",
        patientCountry: examGroup.patient.address?.country_id || 175,
        healthNumber: examGroup.patient.healthNumber || "",
        conventionName: examGroup.patient.conventionName || "",
        conventionNumber: examGroup.patient.conventionNumber || "",
        professionId: examGroup.patient.profession_id?.toString() || "",
        examMotive: "",
        firstAppointment: false,
        notes: "",
        symptoms: "",
        medication: "",
        examRequiringDoc: examGroup.doctor.name || "",
        source: 1,
        selectedStore: examGroup.store,
        selectedExamTypes: [],
        invoiceType: 1
      },
      controlStates: {
        notPortugueseCitizen: false,
        reportFullAddressChecked: false,
        addressOnReportCheckboxDisabled: false
      }
    }

    this.prefilledExamTypeIds = prefilledExamTypeIds
    this.schedulingForExamGroup = examGroup

    return true
  }

  resetData() {
    this.stepOneData = undefined
    this.stepTwoData = undefined
    this.prefilledExamTypeIds = undefined
    this.schedulingForExamGroup = undefined
  }

  createNewAppointment(stepOneData: StepOneData, stepTwoData: StepTwoData): Promise<any> {
    const postBody = {
      stepOneData: stepOneData,
      stepTwoData: stepTwoData
    }

    return firstValueFrom(this.http.post(this.url, postBody, this.httpOptions))
  }

  updateAppointmentDetails(
    appointmentId: number,
    appointmentData: {
      appointment_type_id: number
      scheduled_date: Date
      exam_type_id?: number
      tech_id?: number
      doctor_id?: number,
      appointment_state_id?: number
    }
  ): Promise<any> {
    const httpBody = createHttpBody({
      appointment_type_id: appointmentData.appointment_type_id,
      scheduled_date: appointmentData.scheduled_date,
      exam_type_id: appointmentData.exam_type_id,
      tech_id: appointmentData.tech_id,
      doctor_id: appointmentData.doctor_id,
      appointment_state_id: appointmentData.appointment_state_id
    })

    return firstValueFrom(this.http.put(`${this.url}/${appointmentId}`, httpBody, this.httpOptions))
  }

  updateAppointmentSource(appointmentId: number, source: number): Promise<any> {
    const httpBody = createHttpBody({
      source
    })

    return firstValueFrom(this.http.put(`${this.url}/${appointmentId}`, httpBody, this.httpOptions))
  }

  deleteAppointment(appointmentId: number): Promise<any> {
    return firstValueFrom(this.http.delete(`${this.url}/${appointmentId}`, this.httpOptions))
  }

  getEventDaysForMonth(month: number, year: number): Promise<Appointment[]> {
    const socketParams = createHttpBody({
      month: month,
      year: year
    })
    return this.socketInterceptor.makeCustomRequest(
      'appointments',
      'getMonthDaysWithEvents',
      undefined,
      socketParams
    )
  }

  getAgoraToken(appointmentId: number | string): Promise<any> {
    const socketParams = createHttpBody({
      appointmentId
    })
    return this.socketInterceptor.makeCustomRequest(
      'appointments',
      'getAgoraToken',
      undefined,
      socketParams
    )
  }

  getAccountAgendaByDay(
    day: number | string,
    month: number | string,
    year: number | string
  ): Promise<ApiResponse<Appointment>> {
    const socketParams = createHttpBody({
      day: day,
      month: month,
      year: year
    })
    return this.socketInterceptor.makeCustomRequest(
      'appointments',
      'getAccountAgenda',
      undefined,
      socketParams
    )
  }

  changeAppointmentState(
    appointmentId: number | string,
    state: number
  ): Promise<ApiResponse<Appointment>> {
    const socketParams = createHttpBody({
      appointmentId,
      state
    })
    return this.socketInterceptor.makeCustomRequest(
      'appointments',
      'changeAppointmentState',
      socketParams,
      undefined
    )
  }

  getStoreAgendaByDay(
    day: number | string,
    month: number | string,
    year: number | string,
    storeId: number
  ): Promise<ApiResponse<Appointment>> {
    const socketParams = createHttpBody({
      day: day,
      month: month,
      year: year,
      storeId: storeId
    })
    return this.socketInterceptor.makeCustomRequest(
      'appointments',
      'getStoreAgenda',
      undefined,
      socketParams
    )
  }

  getAllAppointments(
    appointmentsDay: Date,
    storeId?: number,
    doctorId?: number,
    techId?: number
  ): Promise<ApiResponse<Appointment>> {
    const day = appointmentsDay.getDate()
    const month = appointmentsDay.getMonth() + 1
    const year = appointmentsDay.getFullYear()
    const appointmentDay = `${year}-${month}-${day}`

    let httpParams = createHttpParams({
      doctor_id: doctorId,
      scheduled_date_range: `${appointmentDay},${appointmentDay}`,
      operation: ApiOperationTypes.AND,
      store_id: storeId,
      tech_id: techId
    })

    return firstValueFrom(
      this.http.get<ApiResponse<Appointment>>(`${this.url}`, { params: httpParams })
    )
  }

  getAppointmentByCode(code: string): Promise<ApiResponse<Appointment>> {
    const httpParams = createHttpParams({
      code
    })

    return firstValueFrom(
      this.http.get<ApiResponse<Appointment>>(`${this.url}`, {
        params: httpParams
      })
    )
  }

  getAppointmentById(appointmentId: number, associatedAttributes?: number): Promise<Appointment> {
    const httpParams = createHttpParams({
      associatedAttributes: associatedAttributes
    })

    return firstValueFrom(
      this.http.get<Appointment>(`${this.url}/${appointmentId}`, { params: httpParams })
    )
  }

  getAppointmentByExamId(examId: number): Promise<ApiResponse<Appointment>> {
    const httpParams = createHttpParams({
      exam_id: examId
    })

    return firstValueFrom(
      this.http.get<ApiResponse<Appointment>>(`${this.url}`, { params: httpParams })
    )
  }

  addDocumentToAppointment(appointmentId: number, documentId: number): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.appointmentHasDocumentUrl}`,
        { appointment_id: appointmentId, document_id: documentId },
        this.httpOptions
      )
    )
  }

  updateAppointmentDate(appointmentId: number, newDate: Date): Promise<any> {
    return firstValueFrom(
      this.http.put(`${this.url}/${appointmentId}`, { scheduled_date: newDate }, this.httpOptions)
    )
  }

  getAppointmentDocumentsByAppointmentId(appointmentId: number): Promise<AppointmentHasDocument[]> {
    return firstValueFrom(
      this.http.get<AppointmentHasDocument[]>(`${this.appointmentHasDocumentUrl}`, {
        params: { appointment_id: appointmentId }
      })
    )
  }

  addAppointmentProperty(
    name: string,
    value: any,
    appointment_id: number
  ): Promise<AppointmentProperty> {
    return firstValueFrom(
      this.http.post<AppointmentProperty>(
        `${this.appointmentPropertyUrl}`,
        {
          name,
          value,
          appointment_id
        },
        this.httpOptions
      )
    )
  }

  updateAppointmentProperty(appointmentId: number, value: any): Promise<any> {
    return firstValueFrom(
      this.http.put<AppointmentProperty>(
        `${this.appointmentPropertyUrl}/${appointmentId}`,
        {
          value
        },
        this.httpOptions
      )
    )
  }

  updateAppointmentState(appointmentId: number, appointmentStateId: number) {
    return firstValueFrom(
      this.http.put(
        `${this.url}/${appointmentId}`,
        {
          appointment_state_id: appointmentStateId
        },
        this.httpOptions
      )
    )
  }

  updateAppointmentToken(appointmentId: number): Observable<any> {
    return this.http.put(`${this.url}/update_token?id=${appointmentId}`, {}, this.httpOptions).pipe(
      map(data => {
        return data
      })
    )
  }
}
