import { Injectable } from '@angular/core'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { FileService } from './file.service'
import { AppToastService, ToastType } from './app-toast-service.service'
import { ExamTypes } from '../utils/examTypes'
import { PDFDocument, PDFPage } from 'pdf-lib'
import { FeathersjsService } from './feathersjs.service'
import { LoaderService } from './loader.service'
import { Router } from '@angular/router'
import { ReportService } from './report.service'
import { AuthService } from './auth.service'
import { DownloadLogType } from '../models/exam-download-log'
import { DownloadLogService } from './exam-download-log.service'
import { ExamsService } from './exams.service'
import { OrganizationService } from './organization.service'

@Injectable({
  providedIn: 'root'
})
export class GenerateReportService {
  constructor(
    private fileService: FileService,
    private toastService: AppToastService,
    private feathersService: FeathersjsService,
    private loaderService: LoaderService,
    private router: Router,
    private reportService: ReportService,
    private authService: AuthService,
    private downloadLogService: DownloadLogService,
    private examService: ExamsService,
    private organizationService: OrganizationService
  ) {}

  async signReport(examId: number) {
    const signExamData = {
      examId: examId
    }

    try {
      CommonUtils.validateSignExamData(signExamData)

      this.loaderService.show()
      await this.feathersService.socket.emit(
        'signExam',
        'exams',
        signExamData,
        (error: any, data: any) => {
          this.loaderService.hide()
          if (error || data.status === 500) {
            console.log('error: ', error || data)
            this.toastService.show(ToastType.ERROR, 'Erro ao assinar', 'Contacte o administrador.')
          } else if (data.status === 200) {
            this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Exame assinado com sucesso.')
            this.router.navigate(['/dashboard/exams/sign-exams'])
            console.log('status 200: ', data)
          }
        }
      )
    } catch (error) {
      this.loaderService.hide()
      console.log('error: ', error)
      this.toastService.show(ToastType.ERROR, 'Erro ao assinar', 'Contacte o administrador.')
    }
  }

  async downloadSignedReport(
    examId: number,
    downloadType?: DownloadLogType,
    openInNewTab = true
  ): Promise<File | null> {
    const examInfo = await this.examService.getExamByIdNoLock(examId, 7)
    const organizationInfo = examInfo.store.organization
    console.log("examInfo", examInfo);

    if (examInfo.external) {
      let path = examInfo.document_id_document_exam_has_documents![0].path
      let blob = await this.fileService.downloadFile(path)
      CommonUtils.openPdfInTab(blob)
      return null;
      
    }

    let ecgSignReportPage = false

    if (organizationInfo && organizationInfo.template) {
      const template = organizationInfo.template

      if (template.signReportPage) {
        ecgSignReportPage = true
      }
    }

    // merge generated report pdf with selected pages pdf
    const mergedPdf = await PDFDocument.create()

    const signedReportArrayBuffer = await this.reportService.generateReport(examId)

    if (!signedReportArrayBuffer) {
      this.toastService.show(
        ToastType.ERROR,
        'Erro ao gerar relatório. Tente novamente dentro de minutos.',
        'Em caso de persistência ao longo do dia, envie uma situação.'
      )
      return null
    }

    const reportPDFDoc = await PDFDocument.load(signedReportArrayBuffer)

    const selectedPagesDocument = CommonUtils.filterDocumentsByFileName(
      'reportPages.pdf',
      examInfo.document_id_document_exam_has_documents!,
      true
    )
    const selectedPagesPDFDoc = await PDFDocument.load(
      await CommonUtils.blobToArrayBuffer(
        await this.fileService.downloadFile(selectedPagesDocument?.path!)
      )
    )

    let copiedPages: PDFPage[]

    // copy pdf report pages
    copiedPages = await mergedPdf.copyPages(reportPDFDoc, reportPDFDoc.getPageIndices())
    copiedPages.forEach(page => mergedPdf.addPage(page))

    // copy selected exam pages
    if (selectedPagesPDFDoc != null) {
      if (examInfo.exam_type_id == ExamTypes.ECG) {
        if (ecgSignReportPage) {
          let copiedPages = await mergedPdf.copyPages(
            selectedPagesPDFDoc,
            selectedPagesPDFDoc.getPageIndices()
          )
          copiedPages.forEach(page => mergedPdf.addPage(page))
        }
      } else {
        copiedPages = await mergedPdf.copyPages(
          selectedPagesPDFDoc,
          selectedPagesPDFDoc.getPageIndices()
        )
        copiedPages.forEach(page => mergedPdf.addPage(page))
      }
    }

    let pdfSaved = await mergedPdf.save()

    const mergedPdfBlob = new Blob([pdfSaved], { type: 'application/pdf' })
    const mergedPdfFile = new File([mergedPdfBlob], 'signed-report.pdf', {
      type: mergedPdfBlob.type
    })

    if (downloadType) {
      await this.downloadLogService.createLog(
        Number(examId),
        downloadType,
        this.authService.getLoggedInUserId()
      )
    }

    if (openInNewTab) {
      CommonUtils.openPdfInTab(mergedPdfBlob)
      return null
    } else {
      return mergedPdfFile
    }
  }
}
