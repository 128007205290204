import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { ExamLockState } from '../models/examLockState'
import { Exam } from '../models/exam'
import { ExamState } from '../utils/examStates'
import { ReportState } from '../utils/reportStates'
import { ExamComponents } from '../utils/examComponents'
import { VerifyExamState } from '../utils/verifyExamState'
import { ExamFiltersService } from './exam-filters.service'
import { CommonUtils } from '../utils/commonUtils'

export type ExamCount = {
  count: number;
  exams: Exam[];
}

@Injectable({
  providedIn: 'root'
})
export class ExamsRealTimeService {
  constructor(private examFiltersService: ExamFiltersService) { }
  currentExamsList: Exam[] = []
  currentComponent: string = ''
  availableExamsCountByType: ExamCount[] = []

  private examLockStatusSubject: Subject<ExamLockState> = new Subject<ExamLockState>()
  newExamInfoSub = new Subject<{ exam: Exam; index: number; replace?: boolean }>()
  removeExamFromListSub = new Subject<any>()
  availableExamsToRequestCount = new BehaviorSubject<ExamCount[]>([])

  getExamLockStatusObservable(): Observable<ExamLockState> {
    return this.examLockStatusSubject.asObservable()
  }

  pushNewExamLockState(newState: ExamLockState) {
    this.examLockStatusSubject.next(newState)
  }

  emitExamInfo(newExam: Exam) {
    // Find the index where the new exam should be inserted
    const { index, replace } = this.findInsertionIndex(newExam, 'DESC', true)

    if (!this.examFiltersService.filterExam(newExam)) {
      return
    }

    this.newExamInfoSub.next({ exam: newExam, index: index, replace: replace })
  }

  possibleReqExamUpdated(updatedExam: Exam) {
    // console.log('new exam info: ', updatedExam);
    const examCompatibleWithRequestingStateTech1 = VerifyExamState.requestExamForTechLevel1(updatedExam)
    const examCompatibleWithRequestingStateExternal = VerifyExamState.requestExamForExternalTech(updatedExam)

    const transfered_id = updatedExam.transfered_id

    if (examCompatibleWithRequestingStateExternal || examCompatibleWithRequestingStateTech1) {
      if (transfered_id && CommonUtils.isExternalTech()) {
        return
      }

      this.addToAvailableExamsToRequestCount(updatedExam)
      return
    } else {
      this.removeExamFromAvailableExamsToRequestCount(updatedExam)
    }

  }

  addToAvailableExamsToRequestCount(exam: Exam) {
    const currentValues = this.availableExamsToRequestCount.value as ExamCount[]

    if (!currentValues[exam.exam_type_id]) {
      currentValues[exam.exam_type_id] = { count: 0, exams: [] }
    }

    currentValues[exam.exam_type_id].exams.push(exam)
    currentValues[exam.exam_type_id].count++

    this.availableExamsToRequestCount.next(currentValues)
  }

  removeExamFromAvailableExamsToRequestCount(examInfo: Exam) {
    const currentValues = this.availableExamsToRequestCount.value as ExamCount[]

    const examIndex = currentValues[examInfo.exam_type_id]?.exams.findIndex(exam => exam.id == examInfo.id)

    if (examIndex >= 0) {
      currentValues[examInfo.exam_type_id].exams.splice(examIndex, 1)
      currentValues[examInfo.exam_type_id].count--

      this.availableExamsToRequestCount.next(currentValues)
    }

  }

  emitNewExamState(newExamState: any) {
    const newExamStateIndex = this.currentExamsList.findIndex(exam => exam.id == newExamState.id)

    if (newExamStateIndex < 0) {
      return
    }

    if (this.currentComponent == ExamComponents.REPORT_EXAMS) {
      if (!VerifyExamState.reportExams(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }

    if (this.currentComponent == ExamComponents.SIGN_EXAMS) {
      if (!VerifyExamState.signExams(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }

    if (this.currentComponent == ExamComponents.FINALIZED_EXAMS) {
      if (!VerifyExamState.finalizedExams(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }

    if (this.currentComponent == ExamComponents.RECEPTION_EXAMS) {
      if (!VerifyExamState.receptionExams(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }

    if (this.currentComponent == ExamComponents.EXAMS_NO_FILE) {
      if (!VerifyExamState.examsNoFile(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }

    if (this.currentComponent == ExamComponents.EXAMS_TO_REPEAT) {
      if (!VerifyExamState.examsToRepeat(newExamState)) {
        this.removeExamFromListSub.next(newExamStateIndex)
      }
    }
  }

  examWasRemoved(examId: number) {
    const currentListExamIndex = this.currentExamsList.findIndex(exam => exam.id == examId)

    if (currentListExamIndex) {
      this.removeExamFromListSub.next(currentListExamIndex)
    }
  }

  findInsertionIndex(
    receivedExam: Exam,
    listOrder: string,
    considerPriority: boolean
  ): { index: number; replace: boolean } {
    // Find the index where the new exam should be inserted
    const foundIndex = this.currentExamsList.findIndex(exam => exam.id == receivedExam.id)

    if (foundIndex >= 0) {
      return { index: foundIndex, replace: true }
    }

    for (let i = 0; i < this.currentExamsList.length; i++) {
      const currentExam = this.currentExamsList[i]

      if (considerPriority) {
        if (currentExam.priority === 0) {
          return { index: i, replace: false }
        } else if (receivedExam.priority! >= currentExam.priority!) {
          return { index: i, replace: false }
        }
      } else if (currentExam.priority == 0) {
        if (listOrder === 'ASC') {
          if (receivedExam.exam_date! > currentExam.exam_date!) {
            return { index: i, replace: false }
          }
        } else {
          if (receivedExam.exam_date! > currentExam.exam_date!) {
            return { index: i, replace: false }
          }
        }
      }
    }
    return { index: this.currentExamsList.length, replace: false }
  }

  checkForReceptionExams(exam: Exam): boolean {
    if (
      (exam.status_id === ExamState.NEW || exam.status_id === ExamState.CONVERTED) &&
      exam.report_status_id === ReportState.NEW &&
      exam.repeat === 0
    ) {
      return true
    } else {
      return false
    }
  }
}
