import { Appointment } from '../models/appointment'
import { AppointmentProperty } from '../models/appointment-property'
import { HeaderInfoWarning } from '../models/header-info-warning'
import { ExamTypes } from './examTypes'
import { AppointmentAvailabilityTimeslot } from '../models/appointment-availability-timeslot'
import { Timeslot } from '../models/timeslot'
import { Account } from '../models/account'
import { Exam } from '../models/exam'
import { ExamCount } from '../services/exams-real-time.service'
import { ExamRequestGroup } from '../models/exam-request-group'

export class AppointmentUtils {
  static orderReceivedAppointments(appointments: Appointment[]): Appointment[] {
    return appointments.sort((a, b) => {
      // Move appointments with appointment_state_id of 5, 6, or 7 to the end
      const aState = [5, 6, 7].includes(a.appointment_state_id)
      const bState = [5, 6, 7].includes(b.appointment_state_id)

      if (aState && !bState) {
        return 1
      }
      if (!aState && bState) {
        return -1
      }

      // If both appointments have the same appointment_state_id, sort by scheduled_date
      if (a.scheduled_date && b.scheduled_date) {
        return new Date(a.scheduled_date).getTime() - new Date(b.scheduled_date).getTime()
      } else if (a.scheduled_date) {
        return -1
      } else if (b.scheduled_date) {
        return 1
      } else {
        return 0
      }
    })
  }

  static filterAppointmentsOfDay(appointments: Appointment[], day: Date): any[] {
    let filteredAppointments: any[] = []

    day.setHours(0, 0, 0, 0)

    const dateWithoutTime = day.getTime()

    filteredAppointments = appointments.filter(appointment => {
      try {
        const appointmentDate = new Date(appointment.scheduled_date!.toString().split('T')[0])

        return dateWithoutTime === appointmentDate.setHours(0, 0, 0, 0)
      } catch (error) {
        console.log('error filtering on appointment: ', appointment.id)
        return false
      }
    })

    return filteredAppointments
  }

  static getAllAppointmentDaysFromMonth(
    appointments: Appointment[],
    year: number,
    month: number
  ): any[] {
    let days: any[] = []

    appointments.forEach(appointment => {
      const appointmentObj = new Date(appointment.scheduled_date!)
      if (appointmentObj.getMonth() + 1 == month && appointmentObj.getFullYear() == year) {
        if (!days.includes(appointmentObj.getDate())) {
          days.push(appointmentObj.getDate())
        }
      }
    })

    return days
  }

  static infoMsgFromAppt(appointment: Appointment): HeaderInfoWarning {
    let warningInfo!: HeaderInfoWarning

    if (appointment.appointment_type_id < 3) {
      warningInfo = {
        name: `O paciente ${appointment.patient?.name ? appointment.patient?.name : ''
          } fez check-in.`,
        message: 'Clique no link para entrar na consulta.',
        type: 'link',
        appointmentDate: appointment.scheduled_date,
        appointmentId: appointment.id,
        appointmentTypeId: appointment.appointment_type_id,
        link:
          appointment.appointment_type_id == 1
            ? `/dashboard/appointments/offline-appointment/${appointment.id}`
            : `/dashboard/appointments/online-appointment?doctor=true&code=${appointment.code}`
      }
    } else {
      const appointmentTypeId = appointment.appointment_type_id
      const examType = ExamTypes[appointmentTypeId]
      const examId = appointment.exam_id
      const message = examId
        ? `Clique no link para ser redirecionado para o exame do tipo ${examType}.`
        : 'O paciente fez check in mas não tem um exame associado ao agendamento.'
      warningInfo = {
        name: `O paciente ${appointment.patient?.name ? appointment.patient?.name : ''
          } fez check-in.`,
        message: message,
        appointmentDate: appointment.scheduled_date,
        appointmentId: appointment.id,
        appointmentTypeId: appointmentTypeId,
        type: 'link',
        link: examId
          ? `/dashboard/submissions/store-exams-nofile?appointmentId=${appointment.exam_id}`
          : '/dashboard/submissions/store-exams-nofile'
      }
    }

    return warningInfo
  }

  static newExamReqInfo(reqGroup: ExamRequestGroup): HeaderInfoWarning {
    return {
      name: `Novo pedido requisiçao de exames`,
      message: `Paciente: ${reqGroup.patient.name}`,
      type: 'examRequestGroup',
      examRequestGroupId: reqGroup.id,
      examRequestGroup: reqGroup
    }
  }

  static getAllAppointmentFromDay(
    appointments: Appointment[],
    year: number,
    month: number,
    day: number
  ): Appointment[] {
    let filteredAppointments: Appointment[] = []

    if (appointments) {
      appointments.forEach(appointment => {
        const appointmentObj = new Date(appointment.scheduled_date!)
        if (
          appointmentObj.getMonth() + 1 == month &&
          appointmentObj.getFullYear() == year &&
          appointmentObj.getDate() == day
        ) {
          if (!filteredAppointments.includes(appointment)) {
            filteredAppointments.push(appointment)
          }
        }
      })
    }

    return filteredAppointments
  }

  static mergeAppointments(
    storeAppointments: any[],
    appointmentsToMerge: any[],
    date: Date
  ): any[] {
    let mergedAppointments: any[] = []

    storeAppointments.forEach(appointment => {
      if (appointment.doctor_id) {
        appointment.accountName = appointment.doctor.name
      } else {
        appointment.accountName = appointment.tech.name
      }
      mergedAppointments.push(appointment)
    })

    appointmentsToMerge.forEach(appointment => {
      if (new Date(appointment.scheduled_date!).toDateString() === date.toDateString()) {
        mergedAppointments.push(appointment)
      }
    })

    mergedAppointments.sort((a, b) => {
      const dateA = new Date(a.scheduled_date!)
      const dateB = new Date(b.scheduled_date!)

      return dateA.getTime() - dateB.getTime()
    })

    return mergedAppointments
  }

  static getAllAvailableAppointmentDates(
    scheduledAppointments: Appointment[],
    selectedDate: Date,
    accounts: Account[],
    blockDates = true
  ): Timeslot[] {
    const intervalBetweenAppointments = 15 // Interval in minutes

    // Create a map to store unique timeslots with account information
    const timeslotMap = new Map<number, Timeslot>()

    // Function to check if a date is within a given range
    const isDateInRange = (date: Date, startDate: Date, endDate: Date): boolean => {
      return date >= startDate && date <= endDate
    }

    // Iterate over all accounts and their available timeslots
    accounts.forEach(account => {
      // Check if the account is on holiday based on vacancies
      const accountOnHoliday = account.holidays?.some(vacancy => {
        return isDateInRange(selectedDate, new Date(vacancy.start_date), new Date(vacancy.end_date))
      })

      account.appointment_availabilities.forEach(availability => {
        availability.appointment_availability_timeslots?.forEach(timeslot => {
          const timeslotStart = new Date(selectedDate)
          timeslotStart.setHours(
            parseInt(timeslot.start_time.split(':')[0]),
            parseInt(timeslot.start_time.split(':')[1]),
            0,
            0
          )

          const timeslotEnd = new Date(selectedDate)
          timeslotEnd.setHours(
            parseInt(timeslot.end_time.split(':')[0]),
            parseInt(timeslot.end_time.split(':')[1]),
            0,
            0
          )

          let controlDate = new Date(timeslotStart)

          // Create all available times within each timeslot's range
          while (controlDate <= timeslotEnd) {
            const timeKey = controlDate.getTime()
            if (!timeslotMap.has(timeKey)) {
              timeslotMap.set(timeKey, {
                time: new Date(controlDate),
                scheduled: false,
                details: [],
                availableAccounts: [],
                onHolidayAccounts: []
              })
            }
            const currentTimeslot = timeslotMap.get(timeKey)!
            if (accountOnHoliday) {
              if (!currentTimeslot.onHolidayAccounts.some(acc => acc.id === account.id)) {
                currentTimeslot.onHolidayAccounts.push(account)
              }
            } else {
              if (!currentTimeslot.availableAccounts.some(acc => acc.id === account.id)) {
                currentTimeslot.availableAccounts.push(account)
              }
            }

            controlDate.setMinutes(controlDate.getMinutes() + intervalBetweenAppointments)
          }
        })
      })
    })

    // Mark slots that are already scheduled
    scheduledAppointments.forEach((scheduledAppointment: Appointment) => {
      const scheduledDate = new Date(scheduledAppointment.scheduled_date!)
      const scheduledTime = new Date(selectedDate)
      scheduledTime.setHours(scheduledDate.getHours(), scheduledDate.getMinutes(), 0, 0)

      const index = Array.from(timeslotMap.keys()).findIndex(
        timeKey => timeKey === scheduledTime.getTime()
      )

      if (index !== -1) {
        const timeKey = Array.from(timeslotMap.keys())[index]
        const timeSlot = timeslotMap.get(timeKey)!
        timeSlot.scheduled = blockDates
        timeSlot.details.push(scheduledAppointment)
      } else {
        const appointmentInfo: Timeslot = {
          time: scheduledTime,
          scheduled: blockDates,
          details: [scheduledAppointment],
          availableAccounts: [],
          onHolidayAccounts: []
        }

        const insertIndex = Array.from(timeslotMap.keys()).findIndex(
          timeKey => timeKey > scheduledTime.getTime()
        )

        if (insertIndex !== -1) {
          const keys = Array.from(timeslotMap.keys())
          const values = Array.from(timeslotMap.values())
          keys.splice(insertIndex, 0, scheduledTime.getTime())
          values.splice(insertIndex, 0, appointmentInfo)
          timeslotMap.clear()
          keys.forEach((key, i) => timeslotMap.set(key, values[i]))
        } else {
          timeslotMap.set(scheduledTime.getTime(), appointmentInfo)
        }
      }
    })

    return Array.from(timeslotMap.values()).sort((a, b) => a.time.getTime() - b.time.getTime())
  }

  static getAppointmentPropertyByName(
    name: string,
    appointmentProperties?: AppointmentProperty[]
  ): AppointmentProperty | null {
    if (!appointmentProperties) {
      return null
    }

    for (const property of appointmentProperties) {
      if (property.name == name) {
        return property
      }
    }
    return null
  }

  static countExams(res: any) {
    if (res && res.length > 0) {
      let availableExamsCount: ExamCount[] = []
      res.forEach((exam: Exam) => {
        if (availableExamsCount[exam.exam_type_id]) {
          availableExamsCount[exam.exam_type_id].count++
          availableExamsCount[exam.exam_type_id].exams.push(exam)
        } else {
          availableExamsCount[exam.exam_type_id] = {
            count: 1,
            exams: [exam]
          }
        }
      })

      return availableExamsCount
    }

    return []
  }

  static isAppointmentFirstAppointment(appointment: Appointment): boolean {
    let fA = AppointmentUtils.getAppointmentPropertyByName('firstAppointment', appointment.appointment_properties)
    return fA ? fA.value == '1' : false
  }
}
